import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../../utils/url";

const initialState = {
  data: [],
  status: "idle",
  error: null,
  page: 1,
  limit: 50,
  totalCount: 0,
};

export const fetchAdminAllOrders = createAsyncThunk(
  "orderpage/fetchAdminAllOrders",
  async ({ page, limit }, { rejectWithValue }) => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const response = await fetch(
        `${baseUrl}/admin/get-item-request?page=${page}&limit=${limit}`,
        {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        }
      );

      if (!response.ok) {
        return rejectWithValue("Failed to fetch admin orders");
      }

      const data = await response.json();
      return {
        data: data.data,
        totalCount: data.totalCount,
      };
    } catch (error) {
      return rejectWithValue(`Failed to fetch admin orders: ${error.message}`);
    }
  }
);

const orderpageSlice = createSlice({
  name: "allorderpage",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminAllOrders.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAdminAllOrders.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload.data;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchAdminAllOrders.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
      });
  },
});

export const { setPage, setLimit } = orderpageSlice.actions;
export default orderpageSlice.reducer;