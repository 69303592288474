import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useRoutes } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { FaCirclePlay } from "react-icons/fa6";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import DiamondRequestModal from "../../../diamondssearch/diamondCard/diamondCardDaynamic/DiamondRequestModal";
import { useDispatch } from "react-redux";
import { LuArrowBigLeftDash } from "react-icons/lu";
import Loader from "../../../../../Loader/Loader";
import { fetchCartList } from "../../../../../../redux/slice/cartSlice/cartSlice";
import Navbar from "../../../../Navbar/Navbar";
import { baseUrl, baseUrlLocal } from "../../../../../../utils/url";
import Swal from "sweetalert2";
import { GiShare } from "react-icons/gi";
import { PiWhatsappLogoBold } from "react-icons/pi";
import { FaShareNodes } from "react-icons/fa6";
import { IoCloseSharp } from "react-icons/io5";

const JewelryDetails = () => {
  const navigate = useNavigate();
  const { searchId, processedSku } = useParams();

  const type = "jewelry";
  const [jewelryData, setJewelryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShareMenuOpen, setIsShareMenuOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [sendEmail, setSendEmail] = useState("");
  const [sendComments, setSendComments] = useState("");

  const dispatch = useDispatch();

  const validateEmails = (emailString) => {
    const emails = emailString.split(/[,\s;]/).filter(Boolean);
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emails.every((email) => emailPattern.test(email));
  };

  const setPathParams = (type, id) => {
    const url = new URL(window.location.href);
    url.pathname = `/emailshare/${type}/${id}`;

    // Instead of updating the visible URL, use it only internally
    return url.toString();
  };

  const handleEmailShare = async (id) => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json"); // Set the content type to JSON

      if (!validateEmails(sendEmail)) {
        Swal.fire({
          icon: "error",
          title: "Invalid Email Address",
          text: "Please enter a valid email address.",
        });
        return;
      }

      const productIds = id ? Number(id) : [];
      
      const payload = {
        productId: [productIds], 
       type:type,
       comments:sendComments,
       email:sendEmail,
      }
      // await setPathParams(type,id );

      const updatedUrl = setPathParams(type, id);

      const response = await fetch(`${baseUrl}/users/receipt-email`, {
        headers: myHeaders,
        method: "POST",
        body:JSON.stringify(payload),
      });

      const data = await response.json();

      if (data.token) {
        localStorage.setItem("e_token", data.token);
        setShowPopup(false);
      }

      if (!response.ok) {
        throw new Error("Failed to send email");
      }
      Swal.fire({
        icon: "success",
        title: "Email Sent",
        text: "Your email was successfully sent!",
      });

      setSendEmail("");
      setSendComments("");
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error sending the email. Please try again later.",
      });

      console.error("Error to send email", error);
    }
  };

  const handleShareClick = () => {
    setIsShareMenuOpen(!isShareMenuOpen);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const addToCart = async (id) => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          type: "jewelry",
          productId: id,
          quantity: 1,
        }),
        redirect: "follow",
      };

      const response = await fetch(
        `${baseUrl}/users/add-to-cart`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to add item to cart");
      }
      await Swal.fire({
        title: "Success",
        text: "Add To The Cart Successfully!",
        icon: "success",
      });
      dispatch(fetchCartList());
    } catch (error) {
      console.error("Error adding item to cart:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Item Already Add In Cart!",
      });
    }
  };

  useEffect(() => {
    const fetchJewelryData = async () => {
      setLoading(true);
      try {
        const token = sessionStorage.getItem("token");
        if (!token) {
          console.error("Token not found");
          setLoading(false);
          return;
        }
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");

        let url = `${baseUrl}/users/search-results/${searchId}/${processedSku}`;
        let response = await fetch(url, {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        });

        if (!response.ok) {
          if (response.status === 404) {
            url = `${baseUrl}/users/get-from-table/jewelry/${searchId}/${processedSku}`;
            response = await fetch(url, {
              method: "GET",
              headers: myHeaders,
              redirect: "follow",
            });
          }
          if (!response.ok) {
            if (response.status === 401) {
              console.error("Unauthorized: Token expired or invalid");
            } else {
              throw new Error(`Failed to fetch data: ${response.status}`);
            }
          }
        }

        const data = await response.json();
        setJewelryData(data.data);
      } catch (error) {
        console.error("Error fetching jewelry data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (searchId && processedSku) {
      fetchJewelryData();
    } else {
      console.error("Invalid searchId or processedSku");
    }
  }, [searchId, processedSku]);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === getImageFiles(jewelryData[currentIndex]).length - 1
        ? 0
        : prevIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0
        ? getImageFiles(jewelryData[currentIndex]).length - 1
        : prevIndex - 1
    );
  };

  const getImageFiles = (item) => {
    return [
      item["Image File"],
      item["Image File_1"],
      item["Image File_2"],
      item["Image File_3"],
      item["Video File"],
    ].filter((file) => typeof file === "string" && file.trim() !== "");
  };

  const handleItemClick = (index) => {
    const actualIndex = jewelryData.findIndex(
      (item) => item === otherItems[index]
    );
    setCurrentIndex(actualIndex);
    setCurrentImageIndex(0); // Reset image index when switching items
  };

  if (loading) {
    return <Loader />;
  }

  if (!jewelryData?.length) {
    return <div>No data available</div>;
  }

  const currentItem = jewelryData[currentIndex];
  const imageFiles = getImageFiles(currentItem);
  const currentImage = imageFiles[currentImageIndex];
  const isVideo = currentImage?.includes(".mp4");

  const otherItems = jewelryData.filter(
    (item, index) => index !== currentIndex
  );

  const id = jewelryData[0].id;
  const bgcolor = "#fff";

  const handleOnShareClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleWhatsAppShare = () => {
    const message = `Check out this item: ${currentItem["Stock #"] || currentItem["Sku #"]}\n ${currentItem["Title"] || currentItem.Shape
      }${currentItem.Color},${currentItem.Clarity},
    \n https://mail.itemlinkshare.com/emailshare/${type}/${currentItem.id}`;
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <section className="w-full lg:w-[1750px]">
      <div className="flex justify-between px-10 bg-[#feefdc] p-4 rounded-3xl">
        <div className="text-2xl">Search</div>
        <div>
          <Navbar bgcolor={bgcolor} id={id} type={type} />
        </div>
      </div>
      <div className="p-8 w-[1000px] mx-auto font-sans">
        <button
          className="mb-4 text-black flex items-center gap-2 scroll-button"
          onClick={() => navigate(-1)}
        >
          <span>
            <LuArrowBigLeftDash fontSize={20} />
          </span>
          <span> Back to Search Results</span>
        </button>
        <div className="flex flex-col lg:flex-row lg:items-start">
          <div className="w-full lg:w-1/2 relative">
            <div className="relative image-container">
              <button
                onClick={prevImage}
                className={`absolute top-[50%] left-1 transform -translate-y-1/2 bg-gray-200 bg-opacity-50 hover:bg-opacity-75 rounded-full p-1 ${
                  isVideo ? "z-10" : "z-10"
                }`}
              >
                <FaArrowLeft className="h-5 w-5 text-gray-700" />
              </button>

              {isVideo ? (
                <video
                  src={currentImage}
                  alt={currentItem.Title}
                  className="w-full max-w-md rounded-lg object-cover shadow-lg"
                  autoPlay
                  controls
                  muted
                  preload="auto"
                />
              ) : (
                <img
                  src={currentImage}
                  alt={currentItem.Title}
                  loading="lazy"
                  className="w-full max-w-md h-full object-cover rounded-lg shadow-lg"
                  onLoad={(e) => e.target.classList.add("loaded")}
                />
              )}

              <button
                onClick={nextImage}
                className={`absolute top-[50%] right-6 transform -translate-y-1/2 bg-gray-200 bg-opacity-50 hover:bg-opacity-75 rounded-full p-1 ${
                  isVideo ? "z-10" : ""
                }`}
              >
                <FaArrowRight className="h-5 w-5 text-gray-700" />
              </button>
            </div>
            <div className="flex space-x-2 mt-4">
              {imageFiles.map((file, index) => (
                <div key={index} className="relative">
                  {file.includes(".mp4") ? (
                    <>
                      <video
                        src={file}
                        className="w-20 h-20 object-cover rounded-lg shadow-lg"
                        onClick={() => setCurrentImageIndex(index)}
                        preload="auto"
                      />
                      <FaCirclePlay
                        fontSize={40}
                        color="#fff"
                        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-4xl text-white"
                        onClick={() => setCurrentImageIndex(index)}
                      />
                    </>
                  ) : (
                    <img
                      src={file}
                      width={80}
                      height={80}
                      loading="lazy"
                      className="rounded-lg  w-20 h-20 object-cover shadow-lg"
                      onClick={() => setCurrentImageIndex(index)}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="w-full lg:w-1/2 lg:pl-8">
            <h1 className="text-2xl font-bold mb-4">{currentItem.Title}</h1>
            {/* <p className="text-xs">
              Lot Name : {currentItem["Lot Name"]} 
              
            </p> */}
            <p className="text-xs ">
              <span className="font-extrabold">Lot Name :</span>
              <span> {currentItem["Lot Name"]}</span> {"   "}
              <span className="font-extrabold"> Sku No :</span>{" "}
              <span> {currentItem["Sku #"]}</span>
            </p>
            <span className="text-xs">
              Availablity: <b>{currentItem["Avability"]}</b>
            </span>
            {currentItem["Total Price"] && (
              <h2 className="text-xl pt-2 font-semibold text-gray-800 mb-2">
                ${currentItem["Total Price"]?.toLocaleString()} Total Price
              </h2>
            )}
            <ProductDetails details={currentItem} />
            <div className="flex gap-4">
              {/* <button className="px-4 py-2 bg-gray-200 rounded">
                Add to Request List
              </button> */}
              {/* <button
                className="px-4 py-2 bg-[#2688bc] text-white rounded"
                onClick={openModal}
              >
                Request Item
              </button> */}
              {isModalOpen && (
                <DiamondRequestModal
                  TotalPrice={currentItem["Total Price"]}
                  Price={currentItem["Price"]}
                  DiscountPercent={currentItem["Discount Percent"]}
                  processedSku={processedSku}
                  searchId={searchId}
                  isOpen={openModal}
                  onClose={closeModal}
                />
              )}
              <button
                onClick={() => addToCart(currentItem.id)}
                className="px-4 py-2 bg-[#2688bc] text-white rounded"
              >
                Add To Cart
              </button>
              <div className="relative">
                <button
                  className="bg-[#2688bc] rounded-md text-[#fff] py-2 gap-2 flex items-center px-5"
                  onClick={handleShareClick}
                >
                  <GiShare color="#fff" /> Share
                </button>
                {isShareMenuOpen && (
                  <div className="absolute bg-gray-200 shadow-2xl top-11 left-0 rounded-lg p-2 flex flex-col w-52">
                    <button
                      className="flex gap-2 items-center hover:bg-gray-400 p-2 rounded"
                      onClick={handleWhatsAppShare}
                    >
                      <PiWhatsappLogoBold fontSize={18} />
                      Share on WhatsApp
                    </button>
                    <button
                      className="flex gap-2 items-center hover:bg-gray-400 p-2 rounded"
                      onClick={handleOnShareClick}
                    >
                      <FaShareNodes fontSize={18} />
                      Share via Email
                    </button>
                  </div>
                )}

                {showPopup && (
                  <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
                    <div className="bg-white rounded-lg p-6 max-w-md w-full">
                      <div className="flex justify-between items-center">
                        <h3 className="text-lg font-semibold">Share {type}</h3>
                        <button onClick={handleClosePopup}>
                          <IoCloseSharp fontSize={25} />
                        </button>
                      </div>
                      <div className="mt-4">
                        <label className="block text-sm font-semibold text-gray-700">
                          Enter Recipient's Email
                        </label>
                        <input
                          type="email"
                          value={sendEmail}
                          onChange={(e) => setSendEmail(e.target.value)}
                          placeholder="Please Enter Email."
                          className="mt-1 block border p-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          required
                        />
                        <span className="text-sm pt-2">
                          Please use a comma or semicolon to separate multiple
                          email addresses.
                        </span>
                      </div>
                      <div className="mt-6">
                        <label className="block text-sm font-semibold text-gray-700">
                          Your Comment (Optional)
                        </label>
                        <textarea
                          className="mt-1 block p-2 w-full border rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          rows="3"
                          value={sendComments}
                          onChange={(e) => setSendComments(e.target.value)}
                        />
                      </div>

                      <div className="mt-6 flex justify-center gap-6">
                        <button
                          onClick={() => handleEmailShare(currentItem.id)}
                          className="bg-[#3b71ca] text-white px-4 py-2 rounded-md"
                        >
                          Share
                        </button>
                        <button
                          onClick={handleClosePopup}
                          className="bg-[#3b71ca] text-white px-4 py-2 rounded-md"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {otherItems.length > 0 && (
        <div className="mt-12 ">
          <h3 className="text-2xl font-semibold mb-6">
            Similar Items ({otherItems.length})
          </h3>
          <Splide
            options={{
              perPage: 5,
              gap: "20px",
              breakpoints: {
                1024: {
                  perPage: 3,
                },
                768: {
                  perPage: 2,
                },
                640: {
                  perPage: 1,
                },
              },
              pagination: false,
              arrows: true,
              focus: 0,
            }}
          >
            {otherItems.map((item, index) => (
              <SplideSlide key={index}>
                <div
                  className="cursor-pointer w-[350.96]"
                  onClick={() => handleItemClick(index)}
                >
                  <img
                    src={item["Image File"]}
                    alt={item.Title}
                    width={320}
                    loading="lazy"
                    className="rounded-lg object-cover shadow-lg h-80"
                  />
                  <span className="text-sm">
                    Availablity : <b>{item["Avability"]}</b> Lot Name :{" "}
                    <b>{item["Lot Name"]}</b>
                    <br />
                    SKU# {item["Sku #"]}
                  </span>
                  <h4 className="text-sm font-semibold mt-2 pr-3">
                    {item.Title}
                  </h4>
                  <p className="text-black font-semibold">
                    ${item["Total Price"]} Total Diamond Weight{" "}
                    {item["Total Diamond Weight"]}
                  </p>
                  <div className="flex gap-4">
                    {/* <button className="px-4 py-2 bg-gray-200 rounded">
                Add to Request List
              </button> */}
                    {/* <button
                className="px-4 py-2 bg-blue-600 text-white rounded"
                onClick={openModal}
              >
                Request Item
              </button> */}
                    {/* {isModalOpen  && <DiamondRequestModal TotalPrice={item["Total Price"]} Price={item["Price"]} DiscountPercent={item["Discount Percent"]} id={id} isOpen={openModal} onClose={closeModal} />} */}
                    {/* <button
                onClick={addToCart}
                className="px-4 py-2 bg-blue-600 text-white rounded"
              >
                Add To Cart
              </button> */}
                  </div>
                </div>
              </SplideSlide>
            ))}
          </Splide>
        </div>
      )}
      <style>{`
        .image-container {
          position: relative;
          width: 100%;
          height: 448px;
          overflow: hidden;
        }
      `}</style>
    </section>
  );
};

const ProductDetails = ({ details }) => (
  <div className="bg-white p-4 rounded-lg shadow-lg mb-6">
    <h3 className="text-lg font-semibold mb-2 underline">Product Details</h3>
    <div className="flex gap-8 pb-4">
      <ul className="list-none p-0 w-1/2 text-xs">
        <li className="pb-2">
          <strong className="text-sm">Jewelry Type :</strong>{" "}
          {details["Jewelry Type"]}
        </li>
        <li className="pb-2">
          <strong className="text-sm">Jewelry SubType :</strong>{" "}
          {details["Jewelry Subtype"]}
        </li>
        <li className="pb-2">
          <strong className="text-sm">Metal Type :</strong>{" "}
          {details["Metal Type"]}
        </li>
        <li className="pb-2">
          <strong className="text-sm">Setting Type :</strong>{" "}
          {details["Setting Type"]}
        </li>
        <li className="pb-2">
          <strong className="text-sm">Jewelry Size :</strong>{" "}
          {details["Jewelry Size"]}
        </li>
        <li className="pb-2">
          <strong className="text-sm">Orientation :</strong>{" "}
          {details["Orientation"]}
        </li>
      </ul>
      <ul className="list-none p-0 w-1/2 text-xs">
        <li className="pb-2">
          <strong className="text-sm">Avg Center Weight :</strong>{" "}
          {details["Avg Center Weight"]}
        </li>
        <li className="pb-2">
          <strong className="text-sm">Diamond Shape :</strong>{" "}
          {details["Diamond Shape"]}
        </li>
        <li className="pb-2">
          <strong className="text-sm">Total Diamond Weight :</strong>{" "}
          {details["Total Diamond Weight"]}
        </li>
        <li className="pb-2">
          <strong className="text-sm">Total Diamond Pcs :</strong>{" "}
          {details["Total Diamond Pcs"]}
        </li>
        <li className="pb-2">
          <strong className="text-sm">Color/Clarity :</strong>{" "}
          {details["Color"]} {details["Clarity"]}
        </li>
        <li className="pb-2">
          <strong className="text-sm">Lab Name :</strong> {details["Lab Name"]}
        </li>
        <li className="pb-2">
          <strong className="text-sm">Location :</strong> {details["Department Account Name"] === "LA Office" ? "Los Angeles": "New York"}
        </li>
        
      </ul>
    </div>
    <h3 className="text-xl font-semibold mb-2 underline">Description</h3>
    <p className="text-sm">
      {details["Stone Details"]}
      <br />
      {details["Stone Details_1"] && <span>{details["Stone Details_1"]}</span>}
    </p>
  </div>
);

export default JewelryDetails;
