import React, { useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import { CgNotes } from "react-icons/cg";
import { IoSaveOutline } from "react-icons/io5";
import { IoIosRefresh } from "react-icons/io";
import DiamondsSerach from "../../../components/Inventory/Search/diamondssearch/DiamondsSerach";
import JewelerySearch from "../../../components/Inventory/Search/jewelerysearch/JewelerySearch";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AdminDiamondsSearch from "../admininventroyshow/admindiamondsearch/AdminDiamondSearch.js";
import AdminJewelerySearch from "../admininventroyshow/adminjewelrysearch/AdminJewelrySearch.js";
import { decrypt, encrypt } from "../../../utils/EncryptDecrypt.js";

export default function Inventory() {
  const [selectedComponent, setSelectedComponent] = useState("DiamondsSearch");
  const [showInput, setShowInput] = useState(false);


  useEffect(() => {
    const storedType = localStorage.getItem("selectedType");
    if (storedType) {
      setSelectedComponent(decrypt(storedType));
    }
  }, []);

  const toggleInput = () => {
    setShowInput(!showInput);
  };

  const { savesearch, loading, error } = useSelector(
    (state) => state.savesearch
  );

  const handleSelection = (type) => {
    setSelectedComponent(type);
    localStorage.setItem("selectedType", encrypt(type)); // Store the selected type in localStorage
  };

  return (
    <section>
      {/* <div className="lg:flex lg:justify-between flex justify-end lg:px-10 py-5">
        <span className="text-[30px] md:block hidden">
          <h1 className="font-semibold">Search</h1>
        </span>

        <div className="flex lg:gap-5 gap-5">
          <div
            className={`transition-all duration-300 ease-in-out ${
              showInput ? "w-60 opacity-100" : "w-0 opacity-0"
            } overflow-hidden`}
          >
            <input
              type="text"
              className="px-2 py-2 border rounded-md w-full"
              placeholder="Search..."
            />
          </div>
          <button
            className="lg:px-5 px-2 py-2 bg-slate-200 rounded-md"
            onClick={toggleInput}
          >
            <IoSearch fontSize={20} />
          </button>

          <button className="lg:px-5 px-2 py-2 bg-slate-200 rounded-md">
            <CgNotes fontSize={20} />
          </button>

          <button className="lg:px-5 px-2 py-2 bg-slate-200 rounded-md">
            <Link to="/inventorypage/savesearchpage">
              <div className="relative">
                <span className="absolute z-50 bg-blue-500 text-[13px] text-[#fff] rounded-full  px-[8px] -top-[20px]  -right-6">
                  {savesearch?.length}
                </span>
                <IoSaveOutline fontSize={20} />
              </div>
            </Link>
          </button>
          <button className="lg:px-5 px-2 py-2 bg-slate-200 rounded-md">
            <IoIosRefresh fontSize={20} />
          </button>
        </div>
      </div> */}
      <div className="flex font-semibold text-[18px] gap-6 px-10 border-b-2">
        <span
          className={`pb-1 ${selectedComponent === "DiamondsSearch"
              ? "border-b-4 border-blue-500"
              : ""
            }`}
        >
          <button onClick={() => handleSelection ("DiamondsSearch")}>
            Diamonds
          </button>
        </span>
        <span
          className={`pb-1 ${selectedComponent === "JewelrySearch"
              ? "border-b-4 border-blue-500"
              : ""
            }`}
        >
          <button onClick={() => handleSelection ("JewelrySearch")}>
            Jewelry
          </button>
        </span>
      </div>
      <div className="lg:px-10 px-1 ">
        {selectedComponent === "DiamondsSearch" && <AdminDiamondsSearch />}
        {selectedComponent === "JewelrySearch" && <AdminJewelerySearch />}
      </div>
    </section>
  );
}
