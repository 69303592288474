import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../../utils/url";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const initialState = {
  diamondData: [],
  jewelryData: [],
  diamondStatus: "idle",
  jewelryStatus: "idle",
  error: null,
  diamondPage: 1,
  jewelryPage: 1,
  diamondLimit: 50,
  jewelryLimit: 50,
  diamondTotalCount: 0,
  jewelryTotalCount: 0,
  diamondFilter: "All Types",
  jewelryFilter: "All Types",
  activeTab: "diamond",
};

export const fetchAdminDiamondRequests = createAsyncThunk(
  "requests/fetchAdminDiamondRequests",
  async (
    { page, limit, filter = null },
    { rejectWithValue }
  ) => {
    try {
      const token = sessionStorage.getItem("token");
      let url = `${baseUrl}/admin/get-item-request?type=diamond&page=${page}&limit=${limit}`;
      if (filter && filter !== "All Types") {
        url += `&request_type=${filter.toLowerCase()}`;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        return rejectWithValue(
          `Failed to fetch admin diamond requests: ${response.status}`
        );
      }

      const data = await response.json();
      return {
        data: data.data,
        totalCount: data.totalPages,
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchAdminJewelryRequests = createAsyncThunk(
  "requests/fetchAdminJewelryRequests",
  async (
    { page, limit, filter = null },
    { rejectWithValue }
  ) => {
    try {
      const token = sessionStorage.getItem("token");
      let url = `${baseUrl}/admin/get-item-request?type=jewelry&page=${page}&limit=${limit}`;
      if (filter && filter !== "All Types") {
        url += `&request_type=${filter.toLowerCase()}`;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        return rejectWithValue(
          `Failed to fetch admin jewelry requests: ${response.status}`
        );
      }

      const data = await response.json();
      return {
        data: data.data,
        totalCount: data.totalPages,
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// New Async Thunk for Export to CSV
export const exportRequestsToCSV = createAsyncThunk(
  "requests/exportRequestsToCSV",
  async (
    { type, filter = null },
    { rejectWithValue }
  ) => {
    try {
      const token = sessionStorage.getItem("token");
      let url = `${baseUrl}/admin/get-item-request?type=${type}`;
      if (filter && filter !== "All Types") {
        url += `&request_type=${filter.toLowerCase()}`;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        return rejectWithValue(
          `Failed to export requests to CSV: ${response.status}`
        );
      }

      const data = await response.json();
      return {
        data: data.data,
        type, // Include the type (diamond or jewelry) in the returned data
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const requestsSlice = createSlice({
  name: "requests",
  initialState,
  reducers: {
    setDiamondPage: (state, action) => {
      state.diamondPage = action.payload;
    },
    setJewelryPage: (state, action) => {
      state.jewelryPage = action.payload;
    },
    setDiamondLimit: (state, action) => {
      state.diamondLimit = action.payload;
    },
    setJewelryLimit: (state, action) => {
      state.jewelryLimit = action.payload;
    },
    setDiamondFilter: (state, action) => {
      state.diamondFilter = action.payload;
      state.diamondPage = 1;
      state.diamondData = [];
    },
    setJewelryFilter: (state, action) => {
      state.jewelryFilter = action.payload;
      state.jewelryPage = 1;
      state.jewelryData = [];
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminDiamondRequests.pending, (state) => {
        state.diamondStatus = "loading";
      })
      .addCase(fetchAdminDiamondRequests.fulfilled, (state, action) => {
        state.diamondStatus = "succeeded";
        state.diamondData = action.payload.data;
        state.diamondTotalCount = action.payload.totalCount;
      })
      .addCase(fetchAdminDiamondRequests.rejected, (state, action) => {
        state.diamondStatus = "failed";
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchAdminJewelryRequests.pending, (state) => {
        state.jewelryStatus = "loading";
      })
      .addCase(fetchAdminJewelryRequests.fulfilled, (state, action) => {
        state.jewelryStatus = "succeeded";
        state.jewelryData = action.payload.data;
        state.jewelryTotalCount = action.payload.totalCount;
      })
      .addCase(fetchAdminJewelryRequests.rejected, (state, action) => {
        state.jewelryStatus = "failed";
        state.error = action.payload || action.error.message;
      })
      .addCase(exportRequestsToCSV.fulfilled, (state, action) => {
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(action.payload.data);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(
          data,
          action.payload.type + "_data_" + new Date().getTime() + fileExtension
        );
      })
      .addCase(exportRequestsToCSV.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      });
  },
});

export const {
  setDiamondPage,
  setJewelryPage,
  setDiamondLimit,
  setJewelryLimit,
  setDiamondFilter,
  setJewelryFilter,
  setActiveTab,
} = requestsSlice.actions;
export default requestsSlice.reducer;