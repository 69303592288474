//admin request component
import React, { useEffect, useState, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  fetchAdminDiamondRequests,
  fetchAdminJewelryRequests,
  setDiamondPage,
  setJewelryPage,
  setDiamondFilter,
  setJewelryFilter,
  setActiveTab,
  exportRequestsToCSV, // Import exportRequestsToCSV
} from "../../redux/slice/allrequest/AdminAllrequestScice";
import { Button } from "@mui/material";
import { fetchRequestData } from "../../redux/slice/adminrequest/AdminRequestSlice";
import { GrFormNextLink } from "react-icons/gr";
import { GrFormPreviousLink } from "react-icons/gr";


const AdminRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const gridApi = useRef(null);

  const {
    diamondData,
    jewelryData,
    diamondStatus,
    jewelryStatus,
    error,
    diamondPage,
    jewelryPage,
    diamondLimit,
    jewelryLimit,
    diamondTotalCount,
    jewelryTotalCount,
    diamondFilter,
    jewelryFilter,
    activeTab,
  } = useSelector((state) => state.requests);

  const [diamondLoading, setDiamondLoading] = useState(false);
  const [jewelryLoading, setJewelryLoading] = useState(false);

  // --- Fetch Data Functions ---
  const fetchDiamondData = (filter, page, limit) => {
    dispatch(
      fetchAdminDiamondRequests({
        page: page,
        limit: limit,
        filter: filter,
      })
    );
  };

  const fetchJewelryData = (filter, page, limit) => {
    dispatch(
      fetchAdminJewelryRequests({
        page: page,
        limit: limit,
        filter: filter,
      })
    );
  };

  // --- Handlers for Diamond ---
  const handleDiamondFilterClick = (filter) => {
    dispatch(setDiamondFilter(filter));
    dispatch(setActiveTab("diamond"));
  };

  const handleDiamondPageChange = (newPage) => {
    dispatch(setDiamondPage(newPage));
    fetchDiamondData(diamondFilter, newPage, diamondLimit);
  };

  // --- Handlers for Jewelry ---
  const handleJewelryFilterClick = (filter) => {
    dispatch(setJewelryFilter(filter));
    dispatch(setActiveTab("jewelry"));
  };

  const handleJewelryPageChange = (newPage) => {
    dispatch(setJewelryPage(newPage));
    fetchJewelryData(jewelryFilter, newPage, jewelryLimit);
  };

  const handleExportToCSV = (type, filter) => {
    dispatch(exportRequestsToCSV({ type, filter }));
  };
  // --- Column Definitions (No change needed) ---
  const columnDefs = [
    {
      headerName: "Request_ID",
      field: "id",
      width: 120,
      cellRenderer: (params) => {
        return (
          <span
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {params.value}
          </span>
        );
      },
    },
    { headerName: "Request", field: "request_type", width: 100 },
    {
      headerName: "Status",
      field: "status",
      width: 120,
      cellStyle: (params) => {
        if (params.value === "pending") {
          return {
            color: "#FFAF00",
            textTransform: "capitalize",
            fontWeight: "700",
          };
        } else if (params.value === "accept") {
          return {
            color: "green",
            fontWeight: "700",
            textTransform: "capitalize",
          };
        } else if (params.value === "partially accepted") {
          return {
            color: "green",
            fontWeight: "700",
            textTransform: "capitalize",
          };
        } else if (params.value === "decline") {
          return {
            color: "red",
            fontWeight: "700",
            textTransform: "capitalize",
          };
        } else if (params.value === "withdraw") {
          return {
            color: "#3b71ca",
            fontWeight: "700",
            textTransform: "capitalize",
          };
        } else if (params.value === "expire") {
          return {
            color: "red",
            fontWeight: "700",
            textTransform: "capitalize",
          };
        }
        return null;
      },
    },
    { headerName: "Request Type", field: "request_item_type", width: 120 },
    { headerName: "Item Details", field: "item_details", width: 120 },

    { headerName: "Type", field: "type", width: 120 },
    { headerName: "Comments", field: "comments", width: 220 },
    { headerName: "Company Name", field: "company", width: 120 },
    { headerName: "Buyer", field: "buyer", width: 120 },
    { headerName: "Request Portal", field: "request_portal", width: 120 },
    {
      headerName: "Date Requested At",
      field: "created_at",
      width: 220,
      cellRenderer: (params) => {
        let dateStr = params.value.replace(" ", "T").replace("T24:", "T00:");
        let date = new Date(dateStr);
        if (params.value.includes(" 24:")) date.setDate(date.getDate() + 1);
        return date.toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        });
      },
    },
    {
      headerName: "Updated",
      field: "updated_at",
      width: 200,
      cellRenderer: (params) => {
        let dateStr = params.value.replace(" ", "T").replace("T24:", "T00:");
        let date = new Date(dateStr);
        if (params.value.includes(" 24:")) date.setDate(date.getDate() + 1);
        return date.toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        });
      },
    },
  ];

  // --- Handlers (No change needed) ---
  const handleCellClicked = (event) => {
    if (event.colDef.field === "id") {
      let id = event.value;
      const isBulk = event.data.request_type === "bulk";

      // Check if the id starts with "B-" and modify it
      if (typeof id === "string" && id.startsWith("B-")) {
        id = id.split("B-")[1]; // Remove "B-" prefix
      }

      dispatch(fetchRequestData({ id, isBulk }))
        .unwrap()
        .then((responseData) => {
          const queryParam = isBulk ? `bulk_id=${id}` : `id=${id}`;
          navigate(`/adminrequestedynamicidpage?${queryParam}`, {
            state: { responseData, isBulk, id },
          });
        })
        .catch((error) => {
          console.error("Error fetching request:", error);
          alert("Error fetching request.");
        });
    }
  };

  //   const exportDiamondToCSV = () => {
  //     exportDataToCSV(diamondData, "diamond_data.csv");
  //   };

  //   const exportJewelryToCSV = () => {
  //     exportDataToCSV(jewelryData, "jewelry_data.csv");
  //   };
  const exportDiamondToCSV = () => {
    dispatch(exportRequestsToCSV({ type: "diamond", filter: diamondFilter }));
  };

  const exportJewelryToCSV = () => {
    dispatch(exportRequestsToCSV({ type: "jewelry", filter: jewelryFilter }));
  };


  // --- Initial Data Fetch ---
  useEffect(() => {
    fetchDiamondData(diamondFilter, diamondPage, diamondLimit);
    fetchJewelryData(jewelryFilter, jewelryPage, jewelryLimit);
  }, [diamondFilter, diamondPage, diamondLimit, jewelryFilter, jewelryPage, jewelryLimit]);

  // --- Loading States ---
  useEffect(() => {
    setDiamondLoading(diamondStatus === "loading");
    setJewelryLoading(jewelryStatus === "loading");
  }, [diamondStatus, jewelryStatus]);

  // --- Pagination Component (Basic) ---
  const Pagination = ({
    currentPage,
    totalCount,
    limit,
    onPageChange,
    type,
  }) => {
    const totalPages = Math.ceil(totalCount / limit);

    return (
      <div className="flex justify-center gap-1 items-center mt-4">
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-3 py-2 bg-[#3b71ca] rounded-full mx-1 disabled:opacity-50"
        >
          <GrFormPreviousLink color="white" />
        </button>
        <span>
          Page {currentPage} of {totalCount}
        </span>
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalCount}
          className="px-3 py-2 bg-[#3b71ca] rounded-full mx-1 disabled:opacity-50"
        >
          <GrFormNextLink color="white" />
        </button>
      </div>
    );
  };

  return (
    <div className="p-2">
      <h1 className="text-2xl font-bold text-center mb-1">
        Received Requests
      </h1>

      {/* --- Diamond Requests --- */}
      <h2 className="text-xl font-semibold">Diamond Requests</h2>
      <div className="flex flex-wrap justify-between items-center gap-2 mb-2">
        <div className="flex gap-3">
          {["All Types", "Invoice", "Memo", "Hold"].map((filterOption) => (
            <button
              key={filterOption}
              onClick={() => handleDiamondFilterClick(filterOption)}
              className={`px-7 py-1 border border-[#1976d2] rounded-full ${
                diamondFilter === filterOption ? "bg-[#1976d2] text-white" : ""
              }`}
            >
              {filterOption}
            </button>
          ))}
        </div>
        <div className="flex gap-2 mb-2">
          <Button
            variant="contained"
            color="primary"
            onClick={exportDiamondToCSV}
          >
            Export Diamond to CSV
          </Button>
        </div>
      </div>
      <div className="ag-theme-alpine" style={{ height: 300, width: "100%" }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={diamondLoading ? null : diamondData}
          rowSelection="multiple"
          onCellClicked={handleCellClicked}
          overlayLoadingTemplate={
            '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
          }
          overlayNoRowsTemplate={
            '<span class="ag-overlay-loading-center">Loading...</span>'
          }
        />
      </div>

      <Pagination
        currentPage={diamondPage}
        totalCount={diamondTotalCount}
        limit={diamondLimit}
        onPageChange={handleDiamondPageChange}
        type="diamond"
      />

      {/* --- Jewelry Requests --- */}
      <h2 className="text-xl font-semibold mt-1">Jewelry Requests</h2>
      <div className="flex flex-wrap justify-between items-center gap-2 my-2">
        <div className="flex gap-3">
          {["All Types", "Invoice", "Memo", "Hold"].map((filterOption) => (
            <button
              key={filterOption}
              onClick={() => handleJewelryFilterClick(filterOption)}
              className={`px-7 py-1 border border-[#1976d2] rounded-full ${
                jewelryFilter === filterOption ? "bg-[#1976d2] text-white" : ""
              }`}
            >
              {filterOption}
            </button>
          ))}
        </div>
        <div className="flex gap-2 mb-2">
          <Button
            variant="contained"
            color="primary"
            onClick={exportJewelryToCSV}
          >
            Export Jewelry to CSV
          </Button>
        </div>
      </div>
      <div className="ag-theme-alpine" style={{ height: 300, width: "100%" }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={jewelryLoading ? null : jewelryData}
          rowSelection="multiple"
          onCellClicked={handleCellClicked}
          overlayLoadingTemplate={
            '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
          }
          overlayNoRowsTemplate={
            '<span class="ag-overlay-loading-center">Loading...</span>'
          }
        />
      </div>

      <Pagination
        currentPage={jewelryPage}
        totalCount={jewelryTotalCount}
        limit={jewelryLimit}
        onPageChange={handleJewelryPageChange}
        type="jewelry"
      />
    </div>
  );
};

export default AdminRequest;