import React, { useEffect, useRef, useState } from "react";
import { baseUrl } from "../../utils/url";
import AdminEditInventroy from "./admineditinventroy/AdminEditInventroy";
import AdminEditInventoryDia from "./admineditinventroy/AdminEditInventoryDia";
import { IoClose } from "react-icons/io5";
import Swal from "sweetalert2";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Inventory from "./admininventory/Inventory";
import { AdminMainNavbar } from "./admininventroyshow/adminmainnavbar/adminMainNavbar";

const AdminUploadfile = () => {
  const [csvFile, setCsvFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [fileNames, setFileNames] = useState([
    "diamonddata",
    "jewelrysearchdata",
  ]);
  const [loading, setLoading] = useState(false);
  const [stocklot, setStockLot] = useState("");
  const [editInventory, setEditInventory] = useState(false);
  const [inventoryhistory, setInventoryhistory] = useState(false);
  const [editData, setEditData] = useState([]);
  const [dataType, setDataType] = useState("");
  const [popupData, setPopupData] = useState([]);
  const [showUploadForm, setShowUploadForm] = useState(false);

  const [inventoryType, setInventoryType] = useState("diamond");
  const [dataSource, setDataSource] = useState(null);
  const [columnDefsDia, setColumnDefsDia] = useState([]);
  const [error, setError] = useState(null);
  const gridApiRef = useRef(null);

  const fetchData = async (params) => {
    try {
      const token = sessionStorage.getItem("token");
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${token}`);

      // Extract the filter model from params
      const filterModel = params.filterModel;

      // Convert filter model to query parameters
      const filterQuery = Object.keys(filterModel)
        .map((key) => {
          const filter = filterModel[key];
          return `${key}=${encodeURIComponent(filter.filter)}`;
        })
        .join("&");

      // Fetch data from API with filter parameters
      const response = await fetch(
        `${baseUrl}/admin/get-inventory?type=${inventoryType}&page=${
          params.startRow / 25 + 1
        }&${filterQuery}`,
        {
          headers: headers,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();

      // Set row data
      params.successCallback(data.data, data.totalRecords);

      // Derive column definitions from the first item in the data array
      if (data.data.length > 0) {
        const firstItem = data.data[0];
        const dynamicColumnDefs = Object.keys(firstItem).map((key) => ({
          headerName: key.replace(/([A-Z])/g, " $1").toUpperCase(),
          field: key,
          filter: true,
          sortable: true,
          // floatingFilter: true,
        }));
        setColumnDefsDia(dynamicColumnDefs);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.message);
      params.failCallback();
    }
  };

  useEffect(() => {
    setDataSource({
      getRows: fetchData,
    });
  }, [inventoryType]);

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
    params.api.setDatasource(dataSource);
  };

  const handleSumbit = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${token}`);
      const response = await fetch(`${baseUrl}/admin/search/stocklotname`, {
        method: "POST",
        body: JSON.stringify({ stock_lot: stocklot }),
        headers: headers,
      });
      const data = await response.json();

      if (!response.ok) {
        Swal.fire({
          icon: "error",
          title: "Stock and LotName are required",
          text: "",
          showConfirmButton: false,
          timer: 2000,
        });
      }

      if (
        data.message ===
        "No item found in both jewelrysearchdata and diamonddata"
      ) {
        Swal.fire({
          icon: "error",
          title: "No items found for given stock or lot",
          text: "",
          showConfirmButton: false,
          timer: 2000,
        });
      }
      // setStockLot("");
      setEditData(data.data);
      setDataType(data.type);
      setEditInventory(true);
    } catch (error) {
      console.error("Error Search data:", error);
    }
  };
  
  const handlehistory = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${token}`);
      const response = await fetch(`${baseUrl}/admin/get-uploadcsv-history`, {
        method: "GET",
        headers: headers,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setPopupData(result?.data?.reverse());

      setInventoryhistory(true);
    } catch (error) {
      console.error("Error Search data:", error);
    }
  };

  const handleFileChange = (e) => {
    setCsvFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!csvFile) {
      Swal.fire({
        icon: "error",
        title: "Please select a CSV file first.",
        text: "",
        showConfirmButton: false,
        timer: 2000,
      });

      return;
    }

    if (!selectedFileName) {
      Swal.fire({
        icon: "error",
        title: "Please select a file name.",
        text: "",
        showConfirmButton: false,
        timer: 2000,
      });

      return;
    }

    setLoading(true);

    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const uploadData = new FormData();
      uploadData.append("files", csvFile);
      uploadData.append("tableName", selectedFileName);

      const response = await fetch(`${baseUrl}/admin/upload-csv`, {
        method: "POST",
        body: uploadData,
        headers: myHeaders,
        redirect: "follow",
      });

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "File uploaded successfully.",
          text: "",
          showConfirmButton: false,
          timer: 2000,
        });

        setCsvFile(null);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading file.");
    } finally {
      setLoading(false);
    }
  };

  const columnDefs = [
    {
      headerName: "Upload Time",
      field: "upload_time",
      cellRenderer: (params) => {
        if (!params.value) return ""; // Return empty if no value is provided
    
        // Create a new Date object from the value
        const date = new Date(params.value);
    
        // Check if the date is valid
        if (isNaN(date.getTime())) return params.value; // Return original value if date is invalid
    
        // Extract and format the month, day, year, hours, and minutes manually
        const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Get month and pad with leading 0 if needed
        const day = String(date.getUTCDate()).padStart(2, "0"); // Get day and pad with leading 0 if needed
        const year = date.getUTCFullYear(); // Get year
        const hours = String(date.getUTCHours()).padStart(2, "0"); // Get hours in 24-hour format
        const minutes = String(date.getUTCMinutes()).padStart(2, "0"); // Get minutes and pad with leading 0 if needed
    
        // Return formatted string as "MM/DD/YYYY HH:mm"
        return `${month}/${day}/${year} ${hours}:${minutes}`;
      },
    },
    { headerName: "Category", field: "category" },
    { headerName: "Status", field: "status" },
    { headerName: "Total Stones", field: "total_stones" },
    { headerName: "Stones Inserted", field: "stones_inserted" },
    { headerName: "Stones Updated", field: "stones_updated" },
    { headerName: "Data Format", field: "data_format" },
    { headerName: "Fetch Type", field: "fetch_type" },
    { headerName: "Admin Email", field: "admin_email" },
    { headerName: "Message", field: "message" },
  ];

  return (
    <section className="pb-10 pt-5">
      <div>
        <div className="flex justify-between">
          <div className="text-4xl px-5 pb-5">Search</div>
          <div className="flex items-center">
            <button
              onClick={() => setShowUploadForm(true)}
              className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-800"
            >
              Open Upload Form
            </button>
          </div>
        </div>
        <Inventory />

        {/* <div className="ag-theme-alpine h-[83vh]">
          {error && <div className="error">{error}</div>}
          <AgGridReact
            rowModelType="infinite"
            datasource={dataSource}
            onGridReady={onGridReady}
            columnDefs={columnDefsDia}
            paginationPageSize={25}
            cacheBlockSize={25}
            defaultColDef={{
              sortable: true,
              filter: true,
              // floatingFilter: true,
              resizable: true,
            }}
          />
        </div> */}
      </div>
      {showUploadForm && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-10">
          <div className="p-6 bg-gray-100  rounded-lg relative shadow-2xl animate-fadeInUp opacity-95">
            <h2 className="text-xl font-bold mb-4 text-gray-800">
              Upload Form
            </h2>
            <button
              className="absolute top-4 right-4 text-black"
              onClick={() => setShowUploadForm(false)}
            >
              <IoClose fontSize={30} />
            </button>
            <div className="mb-4">
              <div className="mt-4">
                <label className="block text-gray-700 text-sm font-medium mb-2">
                  Select File Name:
                </label>
                <select
                  value={selectedFileName}
                  onChange={(e) => setSelectedFileName(e.target.value)}
                  className="w-full p-3 border border-gray-300 rounded-lg bg-white"
                >
                  <option value="">Select a file</option>
                  {fileNames.map((name, index) => (
                    <option key={index} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
              <label className="block text-gray-700 text-sm font-medium mb-2">
                Upload CSV File:
              </label>
              <input
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                className="w-full p-3 border border-gray-300 rounded-lg bg-white"
                disabled={!selectedFileName}
              />
            </div>
            <div className="flex flex-col gap-2">
              <button
                onClick={handleFileUpload}
                disabled={loading}
                className={`w-full ${
                  loading ? "bg-gray-400" : "bg-blue-600"
                } text-white px-4 py-2 rounded-lg hover:bg-blue-800`}
              >
                {loading ? "Uploading..." : "Upload CSV"}
              </button>
              <button
                onClick={handlehistory}
                className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-800"
              >
                History Inventory
              </button>
            </div>
            <div className="w-full px-2 flex flex-col justify-center my-4">
              <label className="block text-gray-700 text-center mb-2 font-semibold text-[18px]">
                Stock No/Lot Name
              </label>
              <input
                type="text"
                name="Stock No/Lot Name"
                placeholder="Stock No/Lot Name"
                value={stocklot}
                onChange={(e) => setStockLot(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                className="border rounded-lg mt-4 bg-blue-600 text-white py-3"
                onClick={handleSumbit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}

      {editInventory && dataType === "jewelry" && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
          <div className="bg-white p-8  rounded-3xl  w-[80%]  shadow-lg relative">
            <AdminEditInventroy
              editData={editData}
              setEditInventory={setEditInventory}
              dataType={dataType}
              stocklot={stocklot}
            />
          </div>
        </div>
      )}
      {editInventory && dataType === "diamond" && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
          <div className="bg-white p-8 rounded-3xl w-[80%] shadow-lg relative animate-fadeInUp">
            <AdminEditInventoryDia
              editData={editData}
              setEditInventory={setEditInventory}
              dataType={dataType}
              stocklot={stocklot}
            />
          </div>
        </div>
      )}
      {inventoryhistory && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
          <div className="bg-white p-12 rounded-3xl w-[80%] shadow-lg relative animate-fadeInUp">
            <div
              className="ag-theme-alpine"
              style={{ height: 800, width: "100%" }}
            >
              <AgGridReact
                rowData={popupData}
                columnDefs={columnDefs}
                pagination={true}
                paginationPageSize={50}
              />
            </div>
            <button
              className="absolute top-4 right-4 text-black"
              onClick={() => setInventoryhistory(false)}
            >
              <IoClose fontSize={30} />
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default AdminUploadfile;
