import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../../utils/url";

const initialState = {
  data: [],
  status: "idle",
  error: null,
  page: 1,       // Current page
  limit: 50,      // Items per page
  totalCount: 0, // Total number of items on the backend
};

export const fetchAdminAllInvoice = createAsyncThunk(
  "allinvoice/fetchAdminAllInvoice",
  async ({ page, limit }, { rejectWithValue }) => {  // Accept page and limit
    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(
        `${baseUrl}/admin/get-item-request?request_type=invoice&page=${page}&limit=${limit}`,  // Add query params
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        return rejectWithValue(`Failed to fetch admin invoices: ${response.status}`); // Use rejectWithValue
      }

      const data = await response.json();
      // Assuming the backend returns { data: [...], totalCount: 100 }
      return {
        data: data.data,  // Adjust based on your API response structure
        totalCount: data.totalCount, // Adjust based on your API response structure
      };
    } catch (error) {
      return rejectWithValue(error.message); // Use rejectWithValue
    }
  }
);

const allinvoiceSlice = createSlice({
  name: "allinvoice",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminAllInvoice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAdminAllInvoice.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload.data;  // Access the data part
        state.totalCount = action.payload.totalCount; // Store total count
      })
      .addCase(fetchAdminAllInvoice.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
      });
  },
});

export const { setPage, setLimit } = allinvoiceSlice.actions; // Export actions
export default allinvoiceSlice.reducer;