import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { baseUrl } from "../../../utils/url";
import { AgGridReact } from "ag-grid-react";
import { IoMdClose } from "react-icons/io";

export default function AdminUserDetails() {
  const location = useLocation();
  const { userData } = location.state || {};
  const [formData, setFormData] = useState({
    ...userData,
    lockprice_mode: userData?.lockprice_mode === 1,
    allowbuy_memo: userData?.allowbuy_memo === 1,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [companyExists, setCompanyExists] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [role_id, setRoleId] = useState(null);
  const [data, setData] = useState([]);
  const navigate = useNavigate();


  const rolepermissions = sessionStorage.getItem('UrI');


  // Example options for dropdowns
  const statusOptions = [
    { value: "pending", label: "Pending" },
    { value: "approve", label: "Approve" },
    { value: "deactive", label: "Deactive" },
    { value: "decline", label: "Decline" },
  ];
  const roleOptions = [
    { value: "1", label: "Client" },
    { value: "2", label: "Admin" },
  ];
  
  if (rolepermissions === 3 || rolepermissions === "3") {
    roleOptions.push({ value: "3", label: "SuperAdmin" });
  }
  

  useEffect(() => {
    const fecthAdmin = async () => {
      try {
        const token = sessionStorage.getItem("token");
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");

        const response = await fetch(`${baseUrl}/admin/get-admin`, {
          method: "GET",
          headers: myHeaders,
        });
        if (!response.ok) throw new Error("Network response was not ok");
        const data = await response.json();
        setData(data.data);
      } catch (error) {
        console.error("Error fetching admin data:", error);
      }
    };
    const fectUser = async () => {
      try {
        const token = sessionStorage.getItem("token");
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");

        const response = await fetch(`${baseUrl}/admin/get-user`, {
          method: "GET",
          headers: myHeaders,
        });
        if (!response.ok) throw new Error("Network response was not ok");
        const data = await response.json();
        setData(data.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fectUser();
    fecthAdmin();
  }, []);

  useEffect(() => {
    const storedRoleId = formData.role_id;
    setRoleId(storedRoleId);
  }, []);

  useEffect(() => {
    const fetchCompanyList = async () => {
      try {
        const token = sessionStorage.getItem("token");
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
  
        const response = await fetch(`${baseUrl}/admin/company-logo-list`, {
          method: "GET",
          headers: myHeaders,
        });
  
        if (!response.ok) {
          throw new Error("Failed to fetch company list");
        }
  
        const result = await response.json();
        // Filter out empty strings and 'null' company names
        const companies = result.data
          .filter((company) => company.company_name && company.company_name.toLowerCase() !== "null")
          .map((company) => company.company_name);
        setCompanyList(companies);
  
        if (formData.company_name && !companies.includes(formData.company_name)) {
          setCompanyExists(false);
        }
      } catch (err) {
        console.error("Error fetching company list:", err);
        // setError("Failed to fetch company list. Please try again.");
      }
    };
  
    fetchCompanyList();
  }, []);
  

  

  const fetchGetCompanyList = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const response = await fetch(`${baseUrl}/admin/get-company-details`, {
        method: "GET",
        headers: myHeaders,
      });
      if (!response.ok) {
        throw new Error("Failed to fetch company list");
      }
      const result = await response.json();
      setRowData(result.result);
    } catch (err) {
      console.error("Error fetching company list:", err);
      setError("Failed to fetch company list. Please try again.");
    }
  };

  useEffect(() => {
    setColumnDefs([
      { headerName: "ID", field: "id" },
      { headerName: "Name", field: "name" },
      { headerName: "JBT Number", field: "jbtNumber" },
      { headerName: "Website", field: "website" },
      { headerName: "Location Name", field: "locationName" },
      { headerName: "Street Address", field: "streetAddress" },
      { headerName: "Country", field: "country" },
      { headerName: "State", field: "state" },
      { headerName: "City", field: "city" },
      { headerName: "ZIP", field: "zip" },
      { headerName: "Contact Person", field: "contactPerson" },
      { headerName: "Contact Email", field: "contactEmail" },
      { headerName: "Office Phone", field: "officePhone" },
      { headerName: "Mobile Phone", field: "mobilePhone" },
      { headerName: "Facetime Phone", field: "facetimePhone" },
    ]);
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "company_name") {
      setCompanyExists(companyList.includes(value));
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const openPopup = () => {
    setIsPopupOpen(true);
    fetchGetCompanyList();
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleSaveChanges = async (userId) => {
    setIsLoading(true);
    setError(null);
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const formDataToSend = new FormData();

      Object.keys(formData).forEach((key) => {
        if (formData[key] !== undefined) {
          let value =
            typeof formData[key] === "boolean"
              ? formData[key]
                ? "1"
                : "0"
              : formData[key];
          let formKey = key === "company_address" ? "street_address" : key;

          if (key === "allowbuy_memo") {
            value = "1";
          }
          if (key === "lockprice_mode") {
            value = "1";
          }

          formDataToSend.append(formKey, value);
        }
      });

      const response = await fetch(`${baseUrl}/admin/approve/${userId}`, {
        method: "PUT",
        headers: myHeaders,
        body: formDataToSend,
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to update user: ${errorText}`);
      }

      navigate("/adminalluserspage");
    } catch (err) {
      console.error("Error updating user:", err);
      setError("Failed to update user. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-6xl mx-auto py-5 px-8 bg-white shadow-lg rounded-lg border border-gray-200">
      <h1 className="text-3xl font-bold text-center  text-gray-900">Details</h1>
      {
        <form className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-1">
          {/* User Details Section */}
          <div className="col-span-2">
            <h2 className="text-lg font-semibold mb-4">User Details</h2>
          </div>

          {[
            { label: "First Name", name: "first_name" },
            { label: "Last Name", name: "last_name" },
            { label: "Email", name: "email" },
            { label: "Phone", name: "phone" },
          ].map((field, index) => (
            <div
              key={index}
              className={`form-group ${
                field.isCompany ? "flex flex-col items-center" : ""
              }`}
            >
              <span className="flex justify-between items-center w-full">
                <label
                  className={`block text-sm font-medium text-gray-700 mb-1 ${
                    field.isCompany ? "mr-4" : ""
                  }`}
                >
                  {field.label}
                </label>
                {field.isCompany && (
                  <button
                    type="button"
                    onClick={() => navigate("/adminaddnewcompanypage")}
                    className="text-[15px] text-blue-600 underline flex gap-1 items-center"
                  >
                    <FaPlus fontSize={13} /> Add New Company
                  </button>
                )}
              </span>
              {field.isDropdown ? (
                <>
                  <select
                    name={field.name}
                    value={formData[field.name] || ""}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full border-gray-300 border p-2.5 rounded-md shadow-sm focus:border-gray-700 focus:ring-gray-700 text-gray-900"
                  >
                    <option value="">Select Company</option>
                    {companyList.map((company, idx) => (
                      <option key={idx} value={company}>
                        {company}
                      </option>
                    ))}
                  </select>
                  {!companyExists && (
                    <div className="font-light text-sm mt-2">
                      The company{" "}
                      <span className="font-bold">
                        "{formData.company_name}"
                      </span>{" "}
                      which user entered at the time of Registration does not
                      exist. Please select an existing company from the list
                    </div>
                  )}
                </>
              ) : (
                <input
                  type="text"
                  name={field.name}
                  value={formData[field.name] || ""}
                  onChange={handleChange}
                  className="mt-1 block w-full border-gray-300 border p-2 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-gray-900"
                />
              )}
            </div>
          ))}

          {/* Company Details Section */}
          <div className="col-span-2 mt-8">
            <h2 className="text-lg font-semibold mb-4">Company Details</h2>
          </div>

          {[
            { label: "Company Name", name: "company_name" },
            { label: "Address", name: "company_address" },
            { label: "Country", name: "country" },
            { label: "State", name: "state" },
            { label: "City", name: "city" },
            { label: "Zip Code", name: "zip_code" },
            { label: "Company Logo", name: "company_name_logo", isDropdown: true },
          ].map((field, index) => (
            <div
              key={index}
              className={`form-group ${
                field.isCompany ? "flex flex-col items-center" : ""
              }`}
            >
              <span className="flex justify-between items-center w-full">
                <label
                  className={`block text-sm font-medium text-gray-700 mb-1 ${
                    field.isCompany ? "mr-4" : ""
                  }`}
                >
                  {field.label}
                </label>
              </span>
              {field.isDropdown ? (
                <>
                  <select
                    name={field.name}
                    value={formData[field.name] || ""}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full border-gray-300 border p-2.5 rounded-md shadow-sm focus:border-gray-700 focus:ring-gray-700 text-gray-900"
                  >
                    <option value="">Select Company</option>
                    {companyList.map((company, idx) => (
                      <option key={idx} value={company}>
                        {company}
                      </option>
                    ))}
                  </select>
                </>
              ) : (
                <input
                  type="text"
                  name={field.name}
                  value={formData[field.name] || ""}
                  onChange={handleChange}
                  className="mt-1 block w-full border-gray-300 border p-2 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-gray-900"
                />
              )}
            </div>
          ))}

          {/* Status and Role */}
          <div className="col-span-2 mt-8">
            <h2 className="text-lg font-semibold mb-4">Permissions Details</h2>
          </div>

          <div className="col-span-2">
            <div className="grid grid-cols-2 gap-4">
              <div className="form-group">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Status
                </label>
                <select
                  name="status_id"
                  value={formData.status_id || ""}
                  onChange={handleChange}
                  className="mt-1 block w-full border-gray-300 border p-2 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-gray-900"
                >
                  {statusOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Role
                </label>
                <select
                  name="role_id"
                  value={formData.role_id || ""}
                  onChange={(e) => {
                    setRoleId(e.target.value);
                    handleChange(e);
                  }}
                  className="mt-1 block w-full border-gray-300 border rounded-md shadow-sm focus:ring-blue-500 p-2 focus:border-blue-500 sm:text-sm"
                >
                  {roleOptions.map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Permissions */}
            <div className="mt-8">
              {role_id === 2 || role_id === "2" ? (
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Admin Permissions
                  </label>
                  <div className="mt-2 grid grid-cols-3 items-start">
                    {/* <label className="flex items-center">
                      <input
                        type="checkbox"
                        name="dashboard"
                        checked={formData.dashboard || false}
                        onChange={handleChange}
                        className="mr-2"
                      />
                      Dashboard
                    </label> */}
                    {/* <label className="flex items-center">
                      <input
                        type="checkbox"
                        name="request"
                        checked={formData.request || false}
                        onChange={handleChange}
                        className="mr-2"
                      />
                      Request
                    </label> */}
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        name="inventory"
                        checked={formData.inventory || false}
                        onChange={handleChange}
                        className="mr-2"
                      />
                      Inventory
                    </label>
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        name="user"
                        checked={formData.user || false}
                        onChange={handleChange}
                        className="mr-2"
                      />
                      User
                    </label>
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        name="custom_jewelry"
                        checked={formData.custom_jewelry || false}
                        onChange={handleChange}
                        className="mr-2"
                      />
                      Custom Jewelry
                    </label>
                  </div>
                </div>
              ) : role_id === 1 || role_id === "1" ? (
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    User Permissions
                  </label>
                  <div className="mt-2 grid grid-cols-3 items-start">
                    {/* <label className="flex items-center">
                      <input
                        type="checkbox"
                        name="dashboard"
                        checked={formData.dashboard || false}
                        onChange={handleChange}
                        className="mr-2"
                      />
                      Dashboard
                    </label> */}
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        name="loose_stone"
                        checked={formData.loose_stone || false}
                        onChange={handleChange}
                        className="mr-2"
                      />
                      Loose Stone
                    </label>
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        name="jewelry"
                        checked={formData.jewelry || false}
                        onChange={handleChange}
                        className="mr-2"
                      />
                      Jewelry
                    </label>
                    {/* <label className="flex items-center">
                      <input
                        type="checkbox"
                        name="request"
                        checked={formData.request || formData.requests || false}
                        onChange={handleChange}
                        className="mr-2"
                      />
                      Request
                    </label> */}
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        name="custom_jewelry"
                        checked={formData.custom_jewelry || false}
                        onChange={handleChange}
                        className="mr-2"
                      />
                      Custom Jewelry
                    </label>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </form>
      }
      <div className="flex flex-col gap-2  mt-6 w-full">
        <button
          onClick={() => handleSaveChanges(userData.id)}
          disabled={isLoading}
          className="py-2 px-4 w-full bg-[#569ebf] text-white rounded-md hover:bg-[#23627f] transition-colors"
        >
          {isLoading ? "Saving..." : "Save Changes"}
        </button>
        {/* <button
          className="py-2 px-4 w-full bg-[#569ebf] text-white rounded-md hover:bg-[#23627f] transition-colors"
          onClick={openPopup}
        >
          Show Company List
        </button> */}
      </div>
      {error && <div className="text-center text-red-500 mt-4">{error}</div>}
      {isPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-[80%] animate-fadeInUp">
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-bold mb-4">Company List</h2>
              <span className="text-2xl font-bold mb-4 cursor-pointer">
                <IoMdClose onClick={closePopup} />
              </span>
            </div>
            <div
              className="ag-theme-alpine pb-5"
              style={{ height: 750, width: "100%" }}
            >
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                pagination={true}
                paginationPageSize={50}
                rowSelection="single"
              />
            </div>
            <button
              onClick={closePopup}
              className="py-2 px-4 w-full bg-[#569ebf] text-white rounded-md hover:bg-[#23627f] transition-colors"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
