import React, { useState, useEffect } from "react";
import { IoCartOutline, IoCloseSharp } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
import { CiGrid41 } from "react-icons/ci";
import { IoListOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { fetchCartList } from "../../../redux/slice/cartSlice/cartSlice";
import { fetchWishlistSlice } from "../../../redux/slice/wishlistSlice/wishlistSlice";
import { json, Link, useLocation, useNavigate } from "react-router-dom";
import { TbReport, TbReportAnalytics } from "react-icons/tb";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import Swal from "sweetalert2";
import { baseUrl } from "../../../utils/url";
import { fetchUserList } from "../../../redux/slice/getUser/getUser";
import DiamondRequestModal from "../Search/diamondssearch/diamondCard/diamondCardDaynamic/DiamondRequestModal";
import { RiVipDiamondLine } from "react-icons/ri";
import { fetchmodifySearch } from "../../../redux/slice/modifysearch/ModifySearch";
import { FiMenu, FiShoppingCart, FiX } from "react-icons/fi";
import "../../../index.css";
import { decrypt } from "../../../utils/EncryptDecrypt";
import { GrCart } from "react-icons/gr";
import { IoMdShare } from "react-icons/io";
export default function Navbar({
  toggleTableView,
  toggleCardView,
  bgcolor,
  selectedItems,
  type,
  id,
  searchId,
  checkedItems,
  setCheckedItems,
}) {
  const [sendEmail, setSendEmail] = useState("");
  const [sendComments, setSendComments] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalShareOpen, setIsModalShareOpen] = useState(false); // State to store item details for sharing
  const [modalType, setModalType] = useState("");
  const [typeCartItem, setTypeCartItem] = useState("");
  const location = useLocation();
  const isDashboard = location.pathname.includes("/dashboardpage");
  const isCart = location.pathname.includes("/addcartpage");
  const [isCard, setIsCard] = useState(
    location.pathname.includes("/jewelerycardpage/") ||
      location.pathname.includes("/diamondcardpage/") ||
      isCart ||
      isDashboard
  );
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useEffect(() => {
    setIsCard(
      location.pathname.includes("/jewelerycardpage/") ||
        location.pathname.includes("/diamondcardpage/") ||
        isCart ||
        isDashboard
    );
  }, [location.pathname]);
  const dispatch = useDispatch();
  const cartList = useSelector((state) => state.cart.cartList);
  const getUser = useSelector((state) => state.user);
  const userPermission = getUser.user.map((el) => el.allowbuy_memo);
  const first_name = sessionStorage.getItem("first_name");
  const company_name = sessionStorage.getItem("company_name");
  const setPathParams = (type, id) => {
    const url = new URL(window.location.href);
    url.pathname = `/emailshare/${type}/${id}`;
    // Instead of updating the visible URL, use it only internally
    return url.toString();
  };
  const handleEmailShare = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json"); // Set the content type to JSON

      if (!validateEmails(sendEmail)) {
        Swal.fire({
          icon: "error",
          title: "Invalid Email Address",
          text: "Please enter a valid email address.",
        });
        return;
      }

      const productIds = Array.isArray(selectedItems)
        ? selectedItems.map(Number)
        : [];
      const payload = {
        productId: productIds, // Send productId as an array directly
        type: type,
        comments: sendComments,
        email: sendEmail,
      };

      await setPathParams(type, id);

      // Send JSON payload instead of FormData
      const response = await fetch(`${baseUrl}/users/receipt-email`, {
        headers: myHeaders,
        method: "POST",
        body: JSON.stringify(payload), // Convert payload to JSON string
      });

      const data = await response.json();
      setIsModalShareOpen(false);

      if (data.token) {
        localStorage.setItem("e_token", data.token);
      }

      if (!response.ok) {
        throw new Error("Failed to send email");
      }

      Swal.fire({
        icon: "success",
        title: "Email Sent",
        text: "Your email was successfully sent!",
      });

      setSendEmail("");
      setSendComments("");
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error sending the email. Please try again later.",
      });
      console.error("Error sending email:", error);
    }
  };

  // Function to validate email addresses
  const validateEmails = (emailString) => {
    const emails = emailString.split(/[,\s;]/).filter(Boolean);
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emails.every((email) => emailPattern.test(email));
  };
  useEffect(() => {
    if (checkedItems && typeof checkedItems === "object") {
      const checkedItemsArray = Object?.values(checkedItems);
      const typecart = checkedItemsArray?.some(
        (item) => item["Sku"] || item["Sku #"]
      )
        ? "jewelry"
        : "diamond";
      setTypeCartItem(typecart);
    } else {
      console.log("checkedItems is not defined or not an object");
    }
  }, [checkedItems]);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchCartList());
    // dispatch(fetchWishlistSlice());
    dispatch(fetchUserList());
  }, [dispatch]);
  const handleOpenModal = (itemType) => {
    if (
      selectedItems?.length === 0 ||
      (id?.length === 0 &&
        (itemType == "hold" || itemType == "memo" || itemType == "invoice"))
    ) {
      Swal.fire(
        "No items selected",
        `Please select items to ${itemType}.`,
        "info"
      );
      return;
    }
    setModalType(itemType);
    setIsModalOpen(true);
  };
  const handleModalSubmit = async ({
    selectedOption,
    comment,
    totalPriceOffer,
    totalPrice,
    pctOffer,
    payWithin,
    setCheckedItems
  }) => {
    try {
      const token = sessionStorage.getItem("token");
      if (!token) {
        Swal.fire("Error", "Token not found. Please log in again.", "error");
        return;
      }

      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");
      // const id = sessionStorage.getItem("id");


      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          id: selectedItems || [id],
          hold_duration: selectedOption,
          comments: comment,
          request_item_type: modalType,
          type: type,
          total_price_offer: totalPriceOffer,
          total_price: totalPrice,
          pct_offer: pctOffer,
          paywith_in_days: payWithin,
        }),
        redirect: "follow",
      };



      const response = await fetch(
        `${baseUrl}/users/memo-request`,
        requestOptions
      );
      const result = await response.json();

      // Check if response is successful
      if (response.ok) {
        Swal.fire(
          "*Request Submitted",
          `Items ${
            modalType === "hold"
              ? "hold"
              : modalType === "memo"
              ? "memo"
              : "invoice"
          } request submitted!`,
          "success"
        );
        // await removeItemsFromCart(selectedItems);
        dispatch(fetchCartList());
        // setCheckedItems({});
      } else if (
        result.message &&
        result.message.includes("Request already exists for the following IDs:")
      ) {
        Swal.fire("Information", result.message, "info");
        // setCheckedItems({});
      } else {
        const errorMessage = `Failed to ${
          modalType === "hold"
            ? "hold"
            : modalType === "memo"
            ? "memo"
            : "invoice"
        } items`;
        Swal.fire("Error", errorMessage, "error");
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error(
        `Error ${
          modalType === "hold"
            ? "holding"
            : modalType === "memo"
            ? "memo"
            : "invoicing"
        } items:`,
        error
      );
      Swal.fire(
        "Error",
        `An unexpected error occurred: ${error.message}`,
        "error"
      );
    }
  };

  // const removeItemsFromCart = async (selectedIds) => {
  //   try {
  //     const token = sessionStorage.getItem("token");
  //     const myHeaders = new Headers();
  //     myHeaders.append("Authorization", `Bearer ${token}`);

  //     // Loop through selected items and delete each one
  //     for (const id of selectedIds) {
  //       await fetch(`${baseUrl}/users/delete-cart/${id}`, {
  //         method: "DELETE",
  //         headers: myHeaders,
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error removing items from cart:", error);
  //   }
  // };
  const hanldeCompareSubmit = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const response = await fetch(`${baseUrl}/users/search/compare`, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          id: selectedItems || [id],
        }),
        redirect: "follow",
      });
      if (response.ok) {
        const result = await response.json();
        Swal.fire("Success", "Items compared successfully!", "success").then(
          () => {
            navigate("/comparepage");
          }
        );
      } else {
        Swal.fire("Error", "Failed to compare items.", "error");
      }
    } catch (error) {
      Swal.fire("Error", "An unexpected error occurred.", "error");
    }
  };

  const handleOpenModalShare = () => {
    setIsModalShareOpen(true);
  };
  const handleClosePopup = () => {
    setIsModalShareOpen(false);
  };
  const handleModifySearchSubmit = () => {
    dispatch(fetchmodifySearch(searchId))
      .unwrap()
      .then((response) => {
        const route =
          type === "diamond" ? "/diamondsserach" : "/jewelerysearch";
        navigate(route, { state: { data: response } });
      })
      .catch((error) => {
        console.error("Error modifying search:", error);
      });
  };
  const handledownloadSubmit = () => {
    if (!checkedItems || Object.keys(checkedItems).length === 0) {
      Swal.fire("No items selected", "Please select items to export.", "info");
      return;
    }
    const headers = Object.keys(checkedItems[Object.keys(checkedItems)[0]]);
    const rows = Object.values(checkedItems).map((item) =>
      headers.map((header) => item[header] || "")
    );
    const csvContent = [headers, ...rows].map((e) => e.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "exported_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  const addToCart = async (id) => {
    try {
      const token = sessionStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");
      const requestPayload = {
        type: typeCartItem,
        productId: selectedItems,
        quantity: 1,
      };
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestPayload),
        redirect: "follow",
      };
      const response = await fetch(
        `${baseUrl}/users/add-to-cart`,
        requestOptions
      );
      const data = await response.json();
      if (response.ok) {
        await Swal.fire({
          title: "Success!",
          text: data.message,
          icon: "success",
        });
        dispatch(fetchCartList());
        setCheckedItems({});
      } else {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: data.message,
        });
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Item Already Add In Cart!",
      });
    }
  };
  return (
    <div
      className={`flex flex-wrap justify-between max-sm:relative items-center gap-5 px-8 top-0 bg-${bgcolor} z-10`}
    >
      <div className="flex items-center justify-between w-full sm:w-auto">
        <button
          className="sm:hidden text-black hidden-button"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          {isMenuOpen ? <FiX fontSize={25} /> : <FiMenu fontSize={25} />}
        </button>
        {/* <div className="text-white text-lg">Logo</div> */}
      </div>
      <div
        className={`${
          isMenuOpen ? "block" : "hidden"
        } sm:flex sm:flex-warp sm:items-center sm:gap-5 w-full sm:w-auto `}
      >
        {!isDashboard && (
          <div className="flex flex-wrap  gap-5">
            {!isCart && (
              <>
                {isCard || (
                  <div
                    className="flex items-center cursor-pointer gap-2 bg-[#2688bc] text-white text-[13px] py-2 px-2 rounded-lg"
                    onClick={addToCart}
                  >
                    <GrCart fontSize={22} /> <span>Add To Cart</span>{" "}
                  </div>
                )}
                {isCard || (
                  <div
                    className="flex items-center cursor-pointer gap-2 bg-[#2688bc] text-white text-[13px] py-2 px-2 rounded-lg"
                    onClick={handledownloadSubmit}
                  >
                    <LiaFileInvoiceDollarSolid fontSize={25} />{" "}
                    <span>Export CSV</span>
                  </div>
                )}
                {isCard || (
                  <div
                    className="flex items-center cursor-pointer gap-2 bg-[#2688bc] text-white text-[13px] py-2 px-2 rounded-lg"
                    onClick={handleModifySearchSubmit}
                  >
                    <LiaFileInvoiceDollarSolid fontSize={25} />{" "}
                    <span>Modify Search</span>{" "}
                  </div>
                )}
              </>
            )}
            {(userPermission.includes(1) || userPermission.includes("1")) && (
              <>
                <div
                  className="flex group relative items-center cursor-pointer gap-2 bg-[#2688bc] text-white text-[13px] py-2 px-2 rounded-lg"
                  onClick={() =>
                    selectedItems?.length !== 0 && handleOpenModal("hold")
                  }
                >
                  <TbReport fontSize={25} /> <span>Hold Request</span>
                </div>
                <div
                  className="flex group relative items-center cursor-pointer gap-2 bg-[#2688bc] text-white text-[13px] py-2 px-2 rounded-lg"
                  onClick={() =>
                    selectedItems?.length !== 0 && handleOpenModal("memo")
                  }
                >
                  <TbReportAnalytics fontSize={25} />
                  <span>Memo Request</span>
                </div>
                <div
                  className="flex group relative items-center cursor-pointer gap-2 bg-[#2688bc] text-white text-[13px] py-2 px-2 rounded-lg"
                  onClick={() =>
                    selectedItems?.length !== 0 && handleOpenModal("invoice")
                  }
                >
                  <LiaFileInvoiceDollarSolid fontSize={25} />
                  <span>Invoice Request</span>
                </div>
                <div
                  className="flex group relative items-center cursor-pointer gap-2 bg-[#2688bc] text-white text-[13px] py-2 px-2 rounded-lg"
                  onClick={handleOpenModalShare}
                >
                  <IoMdShare fontSize={25} />
                  <span>Share</span>
                </div>
              </>
            )}
            {toggleCardView && (
              <div className="flex relative group items-center cursor-pointer gap-2 bg-[#2688bc] text-white text-[13px] py-2 px-2 rounded-lg">
                <div>
                  <IoListOutline fontSize={25} onClick={toggleCardView} />
                </div>
                <div className="absolute top-12 left-1/2 transform -translate-x-1/2 bg-slate-700 text-white text-xs rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out pointer-events-none group-hover:pointer-events-auto">
                  List View
                </div>
              </div>
            )}
            {toggleTableView && (
              <div className="flex group relative items-center gap-2 bg-[#2688bc] text-white text-[13px] py-2 px-2 rounded-lg">
                <div>
                  <CiGrid41
                    fontSize={25}
                    onClick={toggleTableView}
                    className="cursor-pointer"
                  />
                </div>
                <div className="absolute top-12 left-1/2 transform -translate-x-1/2 bg-slate-700 text-white text-xs rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out pointer-events-none group-hover:pointer-events-auto">
                  Gallery View
                </div>
              </div>
            )}
            {type == "diamond" && (
              <div
                className="flex group relative items-center cursor-pointer gap-2 bg-[#2688bc] text-white text-[13px] py-2 px-2 rounded-lg"
                onClick={hanldeCompareSubmit}
              >
                <RiVipDiamondLine fontSize={25} />
                <div className="absolute top-12 left-1/2 transform -translate-x-1/2 bg-slate-700 text-white text-xs rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out pointer-events-none group-hover:pointer-events-auto">
                  Compare Diamond
                </div>
              </div>
            )}
          </div>
        )}
        <Link to="/inventorypage/addcartpage">
          <div className="bg-[#2688bc] text-white text-[13px] py-2 px-2 rounded-lg relative group">
            <div className="relative">
              <span className="absolute z-50 bg-red-400 text-[14px] text-[#fff] rounded-full px-[6px] -top-[16px] -right-4">
                {cartList?.length}
              </span>
            </div>
            <IoCartOutline fontSize={25} />
            <div className="absolute top-12 left-1/2 transform -translate-x-1/2 bg-slate-700 text-white text-xs rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out pointer-events-none group-hover:pointer-events-auto">
              Cart List
            </div>
          </div>
        </Link>
        <Link to="/userprofilepage">
          <div className="flex gap-2 items-center">
            <CgProfile fontSize={25} />
            <div className="flex flex-col text-[13px]">
              <span>Hi {decrypt(first_name)}</span>
              <span>{company_name}</span>
            </div>
          </div>
        </Link>
      </div>
      <DiamondRequestModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleModalSubmit}
        modalType={modalType}
        selectedItems={selectedItems}
        checkedItems={checkedItems}
        type={type}
        id={id}
        setCheckedItems={setCheckedItems}
      />
      {isModalShareOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-semibold">Share {type}</h3>
              <button onClick={handleClosePopup}>
                {" "}
                <IoCloseSharp fontSize={25} />{" "}
              </button>
            </div>
            <div className="mt-4">
              <label className="block text-sm font-semibold text-gray-700">
                {" "}
                Enter Recipient's Email{" "}
              </label>
              <input
                type="email"
                value={sendEmail}
                onChange={(e) => setSendEmail(e.target.value)}
                placeholder="Please Enter Email."
                className="mt-1 block border p-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                required
              />
              <span className="text-sm pt-2">
                Please use a comma or semicolon to separate multiple
                emailaddresses.
              </span>
            </div>
            <div className="mt-6">
              <label className="block text-sm font-semibold text-gray-700">
                {" "}
                Your Comment (Optional){" "}
              </label>
              <textarea
                className="mt-1 block p-2 w-full border rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                rows="3"
                value={sendComments}
                onChange={(e) => setSendComments(e.target.value)}
              />
            </div>
            <div className="mt-6 flex justify-center gap-6">
              <button
                onClick={handleEmailShare}
                className="bg-[#3b71ca] text-white px-4 py-2 rounded-md"
              >
                {" "}
                Share{" "}
              </button>
              <button
                onClick={handleClosePopup}
                className="bg-[#3b71ca] text-white px-4 py-2 rounded-md"
              >
                {" "}
                Cancel{" "}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
