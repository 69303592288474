import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import { useLocation } from "react-router-dom";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { baseUrl } from "../../utils/url";
import { fetchRequestData } from "../../redux/slice/adminrequest/AdminRequestSlice";
import { useDispatch } from "react-redux";
import { fetchAdminAllOrders } from "../../redux/slice/adminallorders/AdminAllOrders";
import Swal from "sweetalert2";
import { MdCopyAll } from "react-icons/md";

const CustomHeader = (props) => {
  const { copyDataToClipboard } = props;

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span>{props.displayName}</span>
      <button
        onClick={props.onClick}
        style={{ marginLeft: "5px", cursor: "pointer", color: "#568ce6" }}
      >
        <MdCopyAll fontSize={18} />
      </button>
    </div>
  );
};

export default function AdminRequestDynamicId() {
  const location = useLocation();
  const dispatch = useDispatch();
  const gridRef = useRef(null);

  const { responseData, selectedIsBulk, selectedRequestId } =
    location.state || {};
  const [requestDetail, setRequestDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedBulkRequestIds, setSelectedBulkRequestIds] = useState([]);
  const [apiSelectedIds, setApiSelectedIds] = useState(null);
  const [apiSelectedIsBulk, setApiSelectedIsBulk] = useState(null);
  const [isPendingSelected, setIsPendingSelected] = useState(false);
  const [accepting, setAccepting] = useState(false);
  const [declining, setDeclining] = useState(false);

  const gridApiRef = useRef(null);

  const copyDataToClipboard = useCallback((fieldName, message) => {
    const values = [];
    if (gridRef.current) {
      gridRef.current.api.forEachNode((node) => {
        values.push(node.data[fieldName]);
      });
      const textToCopy = values.join("\n");
      navigator?.clipboard
        ?.writeText(textToCopy)
        .then(() => {
          console.log("Copied to clipboard!");
          Swal.fire({
            title: "Copied!",
            text: message,
            icon: "success",
            timer: 1000,
            showConfirmButton: false,
          });
        })
        .catch((err) => console.error(`Failed to copy ${message}: `, err));
    }
  }, []);

  useEffect(() => {
    if (location.state) {
      const { responseData, id, isBulk } = location.state;
      setApiSelectedIds(id);
      setApiSelectedIsBulk(isBulk);

      setRequestDetail(responseData);
      setLoading(false);
    } else {
      setError("No data available");
      setLoading(false);
    }
  }, [location.state]);

  useEffect(() => {
    if (responseData) {
      setRequestDetail(responseData);
      setLoading(false);
    } else {
      setError("No data available");
      setLoading(false);
    }
  }, [responseData]);

  const rowData = useMemo(() => {
    return requestDetail && Array.isArray(requestDetail[0].item_details)
      ? requestDetail[0].item_details.map((item) => ({
          ...item,
        }))
      : [];
  }, [requestDetail]);

  const jewelryColumnDefs = useMemo(
    () => [
      {
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 50,
      },
      { headerName: "Sku #", field: "Sku #", width: 100 },
      {
        headerName: "Lot Name",
        field: "Lot Name",
        width: 130,
        headerComponent: (props) => (
          <CustomHeader
            {...props}
            onClick={() =>
              copyDataToClipboard("Lot Name", "Lot Names copied to clipboard!")
            }
          />
        ),
      },
      {
        headerName: "Status",
        field: "status",
        width: 100,
        cellStyle: (params) => {
          if (params.value === "pending") {
            return {
              color: "#e6b800",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "accept") {
            return {
              color: "green",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "decline") {
            return {
              color: "red",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          }
          return null;
        },
      },
      { headerName: "Total Price", field: "Total Price", width: 100 },
      {
        headerName: "Total Price offer",
        field: "total_price_offer",
        width: 150,
      },
      { headerName: "Pay Within Days", field: "pay_within_days", width: 150 },
      { headerName: "Availability", field: "Avability", width: 120 },
      { headerName: "Title", field: "Title", width: 150 },
      { headerName: "Stone Details", field: "Stone Details", width: 140 },
      { headerName: "Metal Type", field: "Metal Type", width: 140 },
      {
        headerName: "Total Diamond Weight",
        field: "Total Diamond Weight",
        width: 150,
      },
      {
        headerName: "Created At",
        field: "created_at",
        width: 150,
        valueFormatter: (params) => {
          if (!params.value) return ""; // Check if the value exists
          const date = new Date(params.value);

          // Check if the date is valid
          if (isNaN(date.getTime())) return params.value; // Return the original value if date is invalid

          return date.toLocaleString("en-US", {
            month: "2-digit", // "Jan"
            day: "2-digit", // "01"
            year: "numeric", // "2024"
            hour: "2-digit", // "14"
            minute: "2-digit", // "05"
            hour12: false, // For 24-hour format
          });
        },
      },
      {
        headerName: "Updated At",
        field: "updated_at",
        width: 150,
        valueFormatter: (params) => {
          if (!params.value) return ""; // Check if the value exists
          const date = new Date(params.value);

          // Check if the date is valid
          if (isNaN(date.getTime())) return params.value; // Return the original value if date is invalid

          return date.toLocaleString("en-US", {
            month: "2-digit", // "Jan"
            day: "2-digit", // "01"
            year: "numeric", // "2024"
            hour: "2-digit", // "14"
            minute: "2-digit", // "05"
            hour12: false, // For 24-hour format
          });
        },
      },
      { headerName: "update_by", field: "update_by", width: 120 },
      {
        headerName: "Location",
        field: "Department Account Name",
        width: 120,
        valueFormatter: (params) => {
          if (params.value == "NY Office") {
            return "New York";
          }
          if (params.value == "LA Office") {
            return "Los Angeles";
          }
          return params.value; // Return the original value if no match
        },
      }
    ],
    []
  );

  const diamondColumnDefs = useMemo(
    () => [
      {
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 50,
      },
      {
        headerName: "Stock #",
        field: "Stock #",
        width: 100,
        headerComponent: (props) => (
          <CustomHeader
            {...props}
            onClick={() =>
              copyDataToClipboard(
                "Stock #",
                "Stock Numbers copied to clipboard!"
              )
            }
          />
        ),
      }, // Pass the copy function as a prop },
      {
        headerName: "Status",
        field: "status",
        width: 100,
        cellStyle: (params) => {
          if (params.value === "pending") {
            return {
              color: "#e6b800",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "accept") {
            return {
              color: "green",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          } else if (params.value === "decline") {
            return {
              color: "red",
              fontWeight: "700",
              textTransform: "capitalize",
            };
          }
          return null;
        },
      },
      { headerName: "Total Price", field: "Total Price", width: 100 },
      {
        headerName: "Total Price offer",
        field: "total_price_offer",
        width: 150,
      },
      { headerName: "Pay Within Days", field: "paywith_in_days", width: 150 },
      { headerName: "Availability", field: "Availability", width: 120 },
      {
        headerName: "Location",
        field: "Department Account Name",
        width: 120,
        valueFormatter: (params) => {
          if (params.value === "NY Office") {
            return "New York";
          }
          if (params.value === "LA Office") {
            return "Los Angeles";
          }
          return params.value; // Return the original value if no match
        },
      },
      { headerName: "Shape", field: "Shape", width: 100 },
      { headerName: "Weight", field: "Weight", width: 100 },
      { headerName: "Clarity", field: "Clarity", width: 100 },
      { headerName: "Color", field: "Color", width: 100 },
      { headerName: "Discount Percent", field: "Discount Percent", width: 150 },
      {
        headerName: "Created At",
        field: "created_at",
        width: 150,
        valueFormatter: (params) => {
          if (!params.value) return ""; // Check if the value exists
          const date = new Date(params.value);

          // Check if the date is valid
          if (isNaN(date.getTime())) return params.value; // Return the original value if date is invalid

          return date.toLocaleString("en-US", {
            month: "2-digit", // "Jan"
            day: "2-digit", // "01"
            year: "numeric", // "2024"
            hour: "2-digit", // "14"
            minute: "2-digit", // "05"
            hour12: false, // For 24-hour format
          });
        },
      },
      {
        headerName: "Updated At",
        field: "updated_at",
        width: 150,
        valueFormatter: (params) => {
          if (!params.value) return ""; // Check if the value exists
          const date = new Date(params.value);

          // Check if the date is valid
          if (isNaN(date.getTime())) return params.value; // Return the original value if date is invalid

          return date.toLocaleString("en-US", {
            month: "2-digit", // "Jan"
            day: "2-digit", // "01"
            year: "numeric", // "2024"
            hour: "2-digit", // "14"
            minute: "2-digit", // "05"
            hour12: false, // For 24-hour format
          });
        },
      },
      { headerName: "update_by", field: "update_by", width: 120 },
     
    ],
    [copyDataToClipboard]
  );

  

  const onGridReady = useCallback((params) => {
    gridApiRef.current = params.api;
  }, []);

  

  const onSelectionChanged = useCallback(() => {
    const selectedNodes = gridApiRef.current.getSelectedNodes();

    const selectedIds = selectedNodes.map((node) => node.data.id);
    const selectedbulkIds = selectedNodes.map((node) => node.data.request_item_id);
    const isPendingSelected = selectedNodes.some(
      (node) => node.data.status === "pending"
    );
setSelectedBulkRequestIds(selectedbulkIds);

    setSelectedIds(selectedIds);
    setIsPendingSelected(isPendingSelected);
  }, []);

  const handleWithdrawSelected = async (status) => {
    if (status === "accept") {
      setAccepting(true); // Set to true when the request is being accepted
    }
    if (status === "decline") {
      setDeclining(true); // Set to true when the request is being decline
    }


    const requestId = requestDetail?.map(el => el.item_details)?.map(item => item.request_item_id)


    try {
      const token = sessionStorage.getItem("token");
      if (!token) {
        console.error("Token not found");
        setLoading(false);
        setAccepting(false);
        setDeclining(false);
        return;
      }

      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const id =
        requestDetail[0].request_type == "single"
          ? requestDetail[0].id
          : selectedBulkRequestIds;

      const url = `${baseUrl}/admin/update-request-status`;
      const response = await fetch(url, {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify({ status, item_id: selectedIds, id }),
      });

      if (response.ok) {
        const result = await response.json();
        const updatedRequestDetail = requestDetail.map((request) => {
          if (
            request.id === apiSelectedIds ||
            request.bulk_id == apiSelectedIds
          ) {
            const updatedItemDetails = request.item_details.map((item) => {
              if (
                selectedIds.includes(item.id) ||
                selectedIds.includes(item.bulk_id)
              ) {
                return { ...item, status };
              }
              return item;
            });
            return { ...request, item_details: updatedItemDetails };
          }
          return request;
        });

        Swal.fire({
          icon: "success",
          title: `Request ${status} successfully!`,
          showConfirmButton: true,
          timer: 2000,
        });
        dispatch(
          fetchRequestData({ id: apiSelectedIds, isBulk: apiSelectedIsBulk })
        );
        dispatch(fetchAdminAllOrders());
        setRequestDetail(updatedRequestDetail);
      } else {
        const errorText = await response.text();
        console.error(
          "Error withdrawing request:",
          response.statusText,
          errorText
        );
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "There was an issue updating the status. Please try again.",
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.error("Error withdrawing requests:", error);
    } finally {
      setAccepting(false); // Reset accepting state after the operation
      setDeclining(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleExportCsv = () => {
    if (gridApiRef.current) {
      gridApiRef.current.exportDataAsCsv();
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4 capitalize">
        {requestDetail[0].request_item_type} Request Detail
      </h1>
      <div className="p-4">
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div className="p-4 border rounded">
            <h2 className="font-bold mb-2">From</h2>
            <p>{requestDetail[0].company_name}</p>
            <p>{requestDetail[0].company_address}</p>
            <div className="mt-2">
              <p>Contact - {requestDetail[0].contact}</p>
              <p>
                Email -{" "}
                <a
                  href={`mailto:${requestDetail[0].email}`}
                  className="text-blue-500"
                >
                  {requestDetail[0].email}
                </a>
              </p>
              <p>Mobile - {requestDetail[0].phone}</p>
            </div>
            <div>
              <p>comments : {requestDetail[0].comments}</p>
              <p>Hold Duration : {requestDetail[0].hold_duration}</p>
            </div>
          </div>
          <div className="p-4 border rounded grid grid-cols-2">
            <div>
              <h2 className="font-bold mb-2">To</h2>
              <p>{requestDetail[0].seller}</p>
              {/* <p>{requestDetail[0].sellerContactEmail}</p>
            <p>{requestDetail[0].sellerOfficePhone}</p> */}
              <p className="w-[13rem]">
                {requestDetail[0].sellerStreetAddress ||
                  "580 5th Avenue, Suite 1002   "}
              </p>
              <p>{requestDetail[0].sellerCity || "New York,"}</p>
              <p>{requestDetail[0].sellerCountry || "United States 10036 "}</p>
              <p>
                <a
                  href={
                    requestDetail[0].website || "https://www.skylabdiamond.com"
                  }
                  target="_blank" // Opens the link in a new tab
                  rel="noopener noreferrer" // Provides security and performance benefits
                  className="text-blue-500" // Optional: style the link
                >
                  {requestDetail[0].website || "www.skylabdiamond.com"}
                </a>
              </p>
            </div>
            <div>
              <p>
                <strong>Contact:</strong>{" "}
                {requestDetail[0].contactPerson || "Hiren Goti"}
              </p>
              <p className="flex flex-wrap items-center gap-1">
                <strong className="mr-1">Email:</strong>
                {requestDetail[0].contactEmail ? (
                  requestDetail[0].contactEmail
                    .split(";")
                    .map((email, index) => (
                      <span key={index} className="inline-block">
                        <a
                          href={`mailto:${email.trim()}`}
                          className="text-blue-500 hover:text-blue-700 underline"
                        >
                          {email.trim()}
                        </a>
                        {index <
                          requestDetail[0].contactEmail.split(";").length - 1 &&
                          "; "}
                      </span>
                    ))
                ) : (
                  <>
                    <a
                      href="mailto:niren@skylabdiamond.com"
                      className="text-blue-500 hover:text-blue-700 underline"
                    >
                      niren@skylabdiamond.com
                    </a>
                    ;{" "}
                    <a
                      href="mailto:sagar@skylabdiamond.com"
                      className="text-blue-500 hover:text-blue-700 underline"
                    >
                      sagar@skylabdiamond.com
                    </a>
                    ;{" "}
                    <a
                      href="mailto:sagar@skylabdiamond.com"
                      className="text-blue-500 hover:text-blue-700 underline"
                    >
                      hiren@skylabdiamond.com
                    </a>
                  </>
                )}
              </p>

              <p>
                <strong>Office:</strong>{" "}
                {requestDetail[0].officePhone || "+1 646-892-4262"}
              </p>
              <p>
                <strong>Mobile:</strong>{" "}
                {requestDetail[0].mobilePhone || "+1 646-241-8844"}
              </p>
            </div>
          </div>
        </div>

        <div className="flex justify-end mb-4">
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={handleExportCsv}
          >
            Export CSV
          </button>
          {/* <button onClick={copyLotNames} style={{ marginTop: "20px" }}>
        Copy Lot Names
      </button> */}
        </div>

        <div
          className="ag-theme-alpine"
          style={{ height: 400, width: "100%", marginBottom: "1rem" }}
        >
          <AgGridReact
            columnDefs={
              requestDetail[0].type === "jewelry"
                ? jewelryColumnDefs
                : diamondColumnDefs
            }
            ref={gridRef}
            rowData={rowData}
            rowSelection="multiple"
            onGridReady={onGridReady}
            onSelectionChanged={onSelectionChanged}
          />
        </div>

        <div className="flex justify-center my-4 gap-8">
          <button
            className={`w-[10%] bg-green-500 text-white px-4 py-2 rounded
              ${
                isPendingSelected || accepting
                  ? ""
                  : "opacity-50 cursor-not-allowed"
              }`}
            onClick={() => handleWithdrawSelected("accept")}
            disabled={!isPendingSelected || accepting}
          >
            {accepting ? "Accepting..." : "Accept"}
          </button>
          <button
            className={`w-[10%] bg-red-500 text-white px-4 py-2 rounded
            ${
              isPendingSelected || declining
                ? ""
                : "opacity-50 cursor-not-allowed"
            }`}
            onClick={() => handleWithdrawSelected("decline")}
            disabled={!isPendingSelected || declining}
          >
            {declining ? "Declining..." : "Decline"}
          </button>
        </div>
        <div className="flex justify-center gap-8">
          {/* <button
            className={`w-[10%] bg-yellow-500 text-white px-4 py-2 rounded
              ${isPendingSelected ? "" : "opacity-50 cursor-not-allowed"}`}
            onClick={() => handleWithdrawSelected("pending")}
            disabled={!isPendingSelected}
          >
            Pending
          </button> */}
        </div>
      </div>
    </div>
  );
}
