import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../../utils/url";

const initialState = {
  data: [],
  status: "idle",
  error: null,
  page: 1,
  limit: 50,
  totalCount: 0,
};

export const fetchadminallhold = createAsyncThunk(
  "allhold/fetchAdminAllHold",
  async ({ page, limit }, { rejectWithValue }) => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(
        `${baseUrl}/admin/get-item-request?request_type=hold&page=${page}&limit=${limit}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        return rejectWithValue("Failed to fetch admin holds");
      }

      const data = await response.json();
      return {
        data: data.data,
        totalCount: data.totalCount,
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const allholdSlice = createSlice({
  name: "allhold",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchadminallhold.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchadminallhold.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload.data;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchadminallhold.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
      });
  },
});

export const { setPage, setLimit } = allholdSlice.actions;
export default allholdSlice.reducer;